import { createStore, createEffect, createEvent, sample } from 'effector';
import { AxiosError } from 'axios';
import { setError, setSuccess } from 'src/features/notifications';
import { ordersApi, tasksApi } from 'src/shared/api';
import { filteredTransType } from './config';
import { DetailedOrder, OrderTransactions, OrderTransactionItem } from './types';
import { refetchTaskData } from 'src/pages/task/model';
import { refetchOrdersList } from '../ordersList/model';

export const $order = createStore<DetailedOrder | null>(null);
export const $orderId = createStore<string>('');
export const $orderTransactions = createStore<OrderTransactionItem[] | null>(null);

export const initOrderPage = createEvent<string>();
export const clearOrder = createEvent();
export const submitCancelReqData = createEvent<string>();
export const submitReturnReqData = createEvent<ordersApi.ReturnOrderReqData>();
export const submitOrderMeasureReqData = createEvent<ordersApi.OrderMeasureReqData>();
export const submitCancelIntermedary = createEvent<number>();
export const submitChangeStatus = createEvent<number>();
export const refetchOrderData = createEvent();
export const submitPayOrderReqData = createEvent<string>();

export const getOrderFx = createEffect(ordersApi.getOrder);
export const cancelOrderFx = createEffect<string, DetailedOrder, AxiosError<{ message: string }>>(
  async (id: string) => {
    const response = await ordersApi.cancelOrder(id);
    refetchOrdersList();
    return response;
  },
);

export const payOrderFx = createEffect<string, DetailedOrder, AxiosError<{ message: string }>>(async (id: string) => {
  const response = await ordersApi.payPurchaseOrder(id);
  refetchOrdersList();
  return response;
});

export const returnOrderFx = createEffect<ordersApi.ReturnOrderReqData, DetailedOrder, AxiosError<{ message: string }>>(
  ordersApi.returnOrder,
);
export const getOrderTransactionsFx = createEffect(ordersApi.getOrderTransactions);
export const postOrderMeasureFx = createEffect<
  ordersApi.OrderMeasureReqData,
  DetailedOrder,
  AxiosError<{ message: string }>
>(ordersApi.editOrderMeasurements);
export const cancelIntermedaryFx = createEffect(tasksApi.cancelIntermediary);
export const changeStatusFx = createEffect(tasksApi.changeStatus);

$order.on(clearOrder, (_, data) => {})
$order.on(cancelIntermedaryFx.doneData, (order: any, data: any) => {
  const tasks = order?.tasks.map((item: any) => {
    if (item.task.id === data.id) {
      item.intermediary = null;
      item.task.take_in_work_date = null;
    }
    return {
      ...item,
    };
  });

  return {
    ...order,
    tasks,
  };
});

$order.on(getOrderFx.doneData, (_, data) => {
  const history = data?.history ? data.history.reverse() : [];
  return { ...data, history };
});
$orderId.on(initOrderPage, (_, data) => data);
$orderTransactions.on(getOrderTransactionsFx.doneData, (_, data: OrderTransactions) => {
  const filteredItems = (data?.items || []).reverse().reduce((acc, item) => {
    if (
      item.type === filteredTransType &&
      acc.some(element => element.type === filteredTransType && element.status === item.status)
    ) {
      return acc;
    }
    acc = [...acc, item];
    return acc;
  }, [] as OrderTransactionItem[]);

  return filteredItems.reverse();
});

sample({
  clock: [initOrderPage, refetchOrderData],
  source: $orderId,
  target: [getOrderFx, getOrderTransactionsFx],
});

sample({
  clock: submitCancelReqData,
  target: cancelOrderFx,
});

sample({
  clock: submitPayOrderReqData,
  target: payOrderFx,
});

sample({
  clock: submitReturnReqData,
  target: returnOrderFx,
});

sample({
  clock: [cancelOrderFx.doneData, returnOrderFx.doneData, changeStatusFx.doneData, payOrderFx.doneData],
  source: $orderId,
  target: getOrderFx,
});

sample({
  clock: submitOrderMeasureReqData,
  target: postOrderMeasureFx,
});

sample({
  clock: submitCancelIntermedary,
  target: cancelIntermedaryFx,
});

sample({
  clock: submitChangeStatus,
  target: changeStatusFx,
});

sample({
  clock: postOrderMeasureFx.doneData,
  source: $orderId,
  target: getOrderFx,
});

sample({
  clock: postOrderMeasureFx.doneData,
  fn: () => 'Габариты заказа успешно обновлены',
  target: setSuccess,
});

sample({
  clock: postOrderMeasureFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при обновлении габаритов заказа',
  target: setError,
});

sample({
  clock: getOrderFx.failData,
  fn: data => data.message || 'Ошибка при получении заказа',
  target: setError,
});

sample({
  clock: cancelOrderFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при отмене заказа',
  target: setError,
});

sample({
  clock: payOrderFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при оплате заказа',
  target: setError,
});

sample({
  clock: cancelIntermedaryFx.failData,
  fn: (data: any) => data.response?.data?.message || 'Ошибка при снятии посредника',
  target: setError,
});

sample({
  clock: changeStatusFx.failData,
  fn: (data: any) => data.response?.data?.message || 'Ошибка при смене статуса',
  target: setError,
});

sample({
  clock: returnOrderFx.failData,
  fn: data => data.response?.data?.message || 'Ошибка при возврате заказа',
  target: setError,
});

sample({
  clock: cancelOrderFx.doneData,
  fn: () => 'Заказ успешно отменен',
  target: setSuccess,
});

sample({
  clock: returnOrderFx.doneData,
  fn: () => 'Заказ успешно возвращен',
  target: setSuccess,
});

sample({
  clock: cancelIntermedaryFx.doneData,
  fn: () => {
    refetchTaskData();
    refetchOrderData();
    return 'Посредник успешно снят';
  },
  target: setSuccess,
});

sample({
  clock: changeStatusFx.doneData,
  fn: () => {
    refetchTaskData();
    refetchOrderData();
    return 'Статус успешно изменен';
  },
  target: setSuccess,
});
