import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { Button, Col, Row, Spin, Card, Tabs } from 'antd';
import { useIsAdmin } from 'src/widgets/header';
import { BackArrow } from 'src/entities/backArrow';
import { OrdersProductsInfo } from 'src/entities/ordersProductsInfo';
import { ReturnOrderForm } from './ui/returnOrderForm';
import { ConfirmForm } from 'src/shared/ui';
import { MainModal } from 'src/shared/ui';
import { OrdersBaseInfo } from 'src/entities/ordersBaseInfo';
import { orderStatus } from 'src/shared/config';
import { OrdersPrice } from './ui/ordersPrice';
import { OrdersStatus } from './ui/ordersStatus';
import { OrdersDelivery } from './ui/OrdersDelivery';
import { OrdersStatusHistory } from './ui/ordersStatusHistory';
import { OrdersTransactionsHistory } from './ui/ordersTransactionsHistory';
import {
  $order,
  $orderTransactions,
  getOrderFx,
  cancelOrderFx,
  returnOrderFx,
  payOrderFx,
  initOrderPage,
  submitCancelReqData,
  submitReturnReqData,
  submitOrderMeasureReqData,
  submitPayOrderReqData,
} from './model';
import { notAllowedToCancelStatuses } from './config';
import { OrderMeasurements } from 'src/shared/types';
import styles from './Order.module.less';
import classNames from 'classnames';
import { CopyOutlined } from '@ant-design/icons';

interface IProps {
  orderId?: string;
}

export const Order: React.FC<IProps> = ({ orderId }) => {
  const params = useParams<{ id: string }>();
  const prevPath = useLocation().state as { prevPath: boolean };
  const [modalState, setModalState] = useState<{ isDescription: boolean; isConfirm: boolean }>({
    isDescription: false,
    isConfirm: false,
  });
  const [description, setDescription] = useState('');
  const order = useStore($order);
  const orderTransactions = useStore($orderTransactions);
  const isLoading = useStore(getOrderFx.pending);
  const isCancelLoading = useStore(cancelOrderFx.pending);
  const isReturnLoading = useStore(returnOrderFx.pending);
  const isPayLoading = useStore(payOrderFx.pending);
  const isAdmin = useIsAdmin();
  const id = orderId || params.id;

  useEffect(() => {
    if (id) {
      initOrderPage(id);
    }
  }, [id]);

  const handleCancelOrder = () => {
    if (id) {
      submitCancelReqData(id);
    }
  };

  const handleReturnOrder = () => {
    if (id) {
      submitReturnReqData({ id, description });
    }
    setModalState({
      isDescription: false,
      isConfirm: false,
    });
  };

  const handleAddDescription = (value: string) => {
    setDescription(value);
    setModalState({ isDescription: false, isConfirm: true });
  };

  const modalHandler = (type: string, state: boolean) => setModalState({ ...modalState, [type]: state });

  const handleSubmitMeasurements = (value: OrderMeasurements) => {
    if (id) {
      submitOrderMeasureReqData({ id, data: { dimensions: value } });
    }
  };

  const handlePayOrder = async () => {
    if (id) {
      submitPayOrderReqData(id);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.center}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={classNames([styles.order, orderId && styles.pt])}>
      {prevPath ? <BackArrow /> : null}

      {order ? (
        <>
          <div className={styles.header}>
            <div className={styles.title}>
              <h1 className={styles.title}>Заказ №{order?.order?.code || '-'}</h1>
              {order?.order?.code && <CopyOutlined style={{fontSize: 18}} onClick={() => navigator.clipboard.writeText(order?.order?.code)} />}
            </div>
            <div className={styles.buttons}>
              {order?.order?.status === 1 && (
                <Button
                  onClick={handlePayOrder}
                  loading={isPayLoading}
                  disabled={isPayLoading}
                  type="primary"
                  className={styles.primary}>
                  Оплатить
                </Button>
              )}
              {isAdmin && !notAllowedToCancelStatuses.includes(order?.order?.status) ? (
                <Button onClick={handleCancelOrder} loading={isCancelLoading}>
                  Отменить заказ
                </Button>
              ) : null}
            </div>
            {isAdmin && order?.order?.status === orderStatus.done ? (
              <Button onClick={() => modalHandler('isDescription', true)} loading={isReturnLoading}>
                Оформить возврат
              </Button>
            ) : null}
          </div>

          <Row className={styles.info}>
            <Col span={8}>
              <Card>
                <OrdersPrice prices={order?.order?.prices} />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <OrdersBaseInfo
                  title="Клиент"
                  data={{ id: order?.client?.id, name: order?.client?.name, phone: order?.client?.phone }}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <OrdersDelivery
                  order={order}
                  handleSubmitMeasurements={handleSubmitMeasurements}
                  isLoading={isLoading}
                />
              </Card>
            </Col>
          </Row>

          <Row className={styles.infoTasks}>
            <Col span={8}>
              <div className={styles.tasksWrapper}>
                <Card>
                  <OrdersStatus status={order?.order?.status_name} createdAt={order?.order?.created_at} />
                </Card>
                <Card>
                  <Tabs defaultActiveKey="tab2">
                    <Tabs.TabPane tab="Транзакции" key="tab1">
                      <OrdersTransactionsHistory transactions={orderTransactions} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Статусы" key="tab2">
                      <OrdersStatusHistory data={order?.history} />
                    </Tabs.TabPane>
                  </Tabs>
                </Card>
              </div>
            </Col>
            <Col span={16}>
              <div className={styles.tasksWrapper}>
                <Card className={styles.wrapper}>
                  <h2 className={styles.count}>Задания в заказе</h2>
                </Card>
                <div className={styles.ordersInfo}>
                  {order?.order?.items.map((item, index) => (
                    <Card className={styles.taskCard} key={item?.id}>
                      <OrdersProductsInfo
                        orderStatus={order.order.status}
                        data={item}
                        task={order.tasks?.[index]}
                        index={index}
                      />
                    </Card>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Card className={styles.empty}>Заказ не найден</Card>
      )}
      <MainModal isOpen={modalState.isDescription} closeHandler={() => modalHandler('isDescription', false)}>
        <ReturnOrderForm
          successHandler={handleAddDescription}
          closeHandler={() => modalHandler('isDescription', false)}
        />
      </MainModal>
      <ConfirmForm
        isOpen={modalState.isConfirm}
        title="Заказ будет переведен в статус Возврат и должен быть обработан вручную. Подтвердить возврат?"
        closeHandler={() => modalHandler('isConfirm', false)}
        confirmHandler={handleReturnOrder}
      />
    </div>
  );
};
